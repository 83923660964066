<template>
	<div class="home">
		<headeradmin v-if="userInfo?.phone" :positionFixed="true"></headeradmin>
		<headerComp ref="headerCompRef" :positionFixed="true" v-else></headerComp>
		<div class="homeMain">
			<section class="bannerBox">
				<img class="img" src="@/assets/banner.png" alt="" srcset="">
				<div class="content">
					<div class="info">
						<div class="title">
							<div class="t">中国</div>
							<div class="tt">领先的</div>
							<img class="icon" src="@/assets/i1.png" alt="" srcset="">
						</div>
						<h1 class="h1">磁性材料产业互联网平台</h1>
						<h4 class="h4">我们致力于以数字化手段推进磁材产业互联网化</h4>
					</div>
				</div>
			</section>
			<section class="serviceBox">
				<div class="title">
					<div class="text">为磁性材料产业提供多维数字化服务</div>
				</div>
				<div class="dataBlock">
					<div class="content">
						<div class="titleBox">
							<div class="num">01.</div>
							<div class="t">产业数据服务</div>
							<div class="btnT">
								<div class="tt" >联系我们免费试用</div>
								<div class="showCode">
									<div class="text">
										<div class="s">· 微信扫一扫联系我们 ·</div>
									</div>
									<img class="img" src="@/assets/index01.png" alt="" srcset="">
								</div>
							</div>
							
						</div>
						<div class="infoBox">
							<div class="left">
								<div class="line"></div>
								<div class="text">
									我们的数据和洞见 <br>
									能帮您更好地把握市场，<br>
									辅助当下决策并更好地计划未来<br>
								</div>
							</div>
							<div class="rigth">
								<div class="item">
									<div class="tt">
										<img class="i" src="@/assets/i6.png" alt="" srcset="">
										<div class="t">价格数据</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">原料价格</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">废料价格</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">毛坯价格</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">价格预测</div>
									</div>
								</div>
								<div class="item">
									<div class="tt">
										<img class="i" src="@/assets/i7.png" alt="" srcset="">
										<div class="t">运营数据</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">原料产销</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">磁材产销</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">终端产销</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">产销前瞻</div>
									</div>
								</div>
								<div class="item">
									<div class="tt">
										<img class="i" src="@/assets/i8.png" alt="" srcset="">
										<div class="t">深度研究</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">市场资讯</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">市场分析</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">研究报告</div>
									</div>
									<div class="ss">
										<img class="i" src="@/assets/i9.png" alt="" srcset="">
										<div class="t">热点解读</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="dataLine">
						<img class="img1" src="@/assets/i3.png" alt="" srcset="">
						<img class="img2" src="@/assets/i4.png" alt="" srcset="">
					</div>
				</div>
				<div class="dataBlock2">
					<div class="content">
						<div class="titleBox">
							<div class="num">02.</div>
							<div class="t">数字化营销服务</div>
							<div class="btnT">
								<div class="tt" >联系我们了解更多</div>
								<div class="showCode">
									<div class="text">
										<div class="s">· 微信扫一扫联系我们 ·</div>
									</div>
									<img class="img" src="@/assets/index02.png" alt="" srcset="">
								</div>
							</div>
						</div>
						<div class="itemBoxNum">
							<div class="item">
								<div class="num">100,000+</div>
								<div class="lable">产业人群</div>
							</div>
							<div class="item">
								<div class="num">50,000+</div>
								<div class="lable">活跃用户</div>
							</div>
							<div class="item">
								<div class="num">10,000+</div>
								<div class="lable">全球订单</div>
							</div>
							<div class="item">
								<div class="num">100+</div>
								<div class="lable">采供活动</div>
							</div>
						</div>
					</div>
					<div class="imgBox">
						<div class="item">
							<img class="img" src="@/assets/i10.png" alt="">
							<div class="titleB">
								<img class="i1" src="@/assets/i13.png" alt="">
								<div class="t">工厂展示</div>	
								<img class="i2" src="@/assets/i13.png" alt="">
							</div>
						</div>
						<div class="item">
							<img class="img" src="@/assets/i11.png" alt="">
							<div class="titleB">
								<img class="i1" src="@/assets/i13.png" alt="">
								<div class="t">优质订单</div>	
								<img class="i2" src="@/assets/i13.png" alt="">
							</div>
						</div>
						<div class="item">
							<img class="img" src="@/assets/i12.png" alt="">
							<div class="titleB">
								<img class="i1" src="@/assets/i13.png" alt="">
								<div class="t">采供对接</div>	
								<img class="i2" src="@/assets/i13.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="tradingBox">
				<div class="dataBlock">
					<div class="content">
						<div class="titleBox">
							<div class="num">03.</div>
							<div class="t">数字化交易服务</div>
							<div class="btnT">
								<div class="tt" >联系我们了解更多</div>
								<div class="showCode">
									<div class="text">
										<div class="s">· 微信扫一扫联系我们 ·</div>
									</div>
									<img class="img" src="@/assets/index03.png" alt="" srcset="">
								</div>
							</div>
						</div>
						<div class="itemBox">
							<div class="item bg1">
								<div class="main">
									<div class="name">原材料集合采购</div>
									<div class="msg">通过集合大量采购订单，助您获得更大的议价空间，降低采购成本</div>
									<div class="tagBox">
										<div class="tag">
											<img class="i" src="@/assets/i18.png" alt="" srcset="">
											<div class="t">价格更低</div>
										</div>
										<div class="tag">
											<img class="i" src="@/assets/i19.png" alt="" srcset="">
											<div class="t">品质更优</div>
										</div>
										<div class="tag">
											<img class="i" src="@/assets/i20.png" alt="" srcset="">
											<div class="t">效率更高</div>
										</div>
										<div class="tag">
											<img class="i" src="@/assets/i21.png" alt="" srcset="">
											<div class="t">服务更好</div>
										</div>
									</div>
								</div>
							</div>
							<div class="item bg2">
								<div class="main">
									<div class="name">钕铁硼现货商城</div>
									<div class="msg">多规格钕铁硼毛坯和成品现货，质优价廉，即刻送达</div>
									<div class="tagBox">
										<div class="tag">
											<img class="i" src="@/assets/i24.png" alt="" srcset="">
											<div class="t">规格多</div>
										</div>
										<div class="tag">
											<img class="i" src="@/assets/i25.png" alt="" srcset="">
											<div class="t">发货快</div>
										</div>
										<div class="tag">
											<img class="i" src="@/assets/i26.png" alt="" srcset="">
											<div class="t">质量好</div>
										</div>
										<div class="tag">
											<img class="i" src="@/assets/i27.png" alt="" srcset="">
											<div class="t">价格省</div>
										</div>
									</div>
								</div>
							</div>
							<div class="item bg3">
								<div class="main">
									<div class="name">钕铁硼按需制造</div>
									<div class="msg">从磁体设计到生产，找磁材庞大的供应商和行业专家网络可以满足您所有的制造需求。</div>
									<div class="tagBox">
										<div class="tag">
											<img class="i" src="@/assets/i29.png" alt="" srcset="">
											<div class="t">1000+制造商</div>
										</div>
										<div class="tag">
											<img class="i" src="@/assets/i30.png" alt="" srcset="">
											<div class="t">1000+工程师</div>
										</div>
										<div class="tag">
											<img class="i" src="@/assets/i31.png" alt="" srcset="">
											<div class="t">10000+订单经验</div>
										</div>
										<div class="tag">
											<img class="i" src="@/assets/i32.png" alt="" srcset="">
											<div class="t">免费快速打样</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="newsBox">
				<div class="title">
					<div class="text">产业动态</div>
				</div>
				<div class="content">
					<div class="navBox">
						<div class="btn" v-for="(item,index) in aticleSorts" :class="navIdx == index?'active':''" @click="onNav(index)">{{item.type}}</div>
					</div>
					<div class="newBox" v-if="total > 0">
						<div class="left" v-if="aticleData?.topitem"  @click="IndustrialDetails(aticleData?.topitem)">
							<div class="imgBox">
								<img class="img" :src="aticleData?.topitem.imgurl" alt="" srcset="">
							</div>
							<div class="titleT">{{ aticleData?.topitem.title }}</div>
							<div class="msg" >{{ aticleData?.topitem.content_txt }}</div>
							<div class="time">{{ aticleData?.topitem.create_time}}</div>
						</div>
						<div class="line" v-if="aticleData?.topitem"></div>
						<div class="right">
							<div class="list" v-for="(item,index) in aticleData?.lists?.data" :key="index" @click="IndustrialDetails(item)">
								<div class="info">
									<div class="t">{{ item.title }}</div>
									<div class="m">{{ item.content_txt }}</div>
								</div>
								<div class="moreTime">
									<div class="more">
										<div class="t">查看</div>
										<img class="i" src="@/assets/i34.png" alt="" srcset="">
									</div>
									<div class="time">{{ item.create_time }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="noData noData2" v-else>
						<img class="i" src="@/assets/quesheng.png" alt="" srcset="" >
						<div class="t">暂无数据</div>
					</div>
				</div>
				<div class="pagination" v-if="total > 0">
					<el-pagination layout="prev, pager, next" v-model:current-page="page" :total="total" :page-size="5" @change="changePage" />
				</div>
			</section>
			<div class="loginContent" v-if="showLogin">
				<div class="bg"></div>
				<div class="contentT">
					<div class="box">
						<img class="close" src="@/assets/i38.png" alt="" @click="onshowLogin">
						<div class="fromBox fromBox2" >
							<div class="title">联系我们</div>
							<div class="inputBox" >
								<div class="input">
									<div class="lable">公司名称</div>
									<el-input v-model="formData.company" placeholder="请输入公司名称" />
								</div>
								
								<div class="input">
									<div class="lable">联系人</div>
									<el-input v-model="formData.name" placeholder="请输入联系人姓名" />
								</div>
								<div class="input">
									<div class="lable">职务</div>
									<el-input v-model="formData.position" placeholder="请输入职务" />
								</div>
								<div class="input">
									<div class="lable">手机号</div>
									<el-input type="number" v-model="formData.phone" placeholder="请输入手机号码" />
								</div>
								<div class="input">
									<div class="lable">验证码</div>
									<el-input class="codeIpnut" v-model="formData.code" placeholder="请输入验证码" />
									<div class="yanma" @click="!disabledT?sendsmsCode(1):''">{{ time }}</div>
								</div>
							</div>
							<div class="btnTT" @click="onContact()">提 交</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footerComp></footerComp>
	</div>
</template>

<script lang="ts">
import { defineComponent,ref,onMounted,onUnmounted,watch } from 'vue';
import headerComp from '@/components/header.vue'
import headeradmin from '@/components/headeradmin.vue'
import footerComp from '@/components/footer.vue'
import {postApi,getApi} from '@/utils/index'
import { useRouter,useRoute } from "vue-router"
import { ElMessage } from 'element-plus'
export default defineComponent({
	name: 'HomeView',
	components: {
		headerComp,
		footerComp,
		headeradmin
	},
	setup() {
		const navIdx:any = ref(0)
		const aticleData:any = ref([])
		const aticleSorts:any = ref([])
		const indexNav:any = ref([])
		const userInfo:any = ref()
		const total:any = ref(0)
		const navTopId = ref(<any>'')

		const formData = ref({
			phone: '15011525758',
			password: '15011525758',
			password2: '',
			code: '',
			company: '',
			position: '',
			name: ''
		})
		const showLogin = ref(false)
		const time = ref('发送验证码')
		const currentTime = ref(60)
		const interval = ref(0)
		const disabledT = ref(false)
		const page = ref(1)
		const onNav = (index:any) => {
			navIdx.value = index
			page.value = 1
			getArticle()
		}
		// 产业动态
		const getArticleSorts = async () => {
			let res = await getApi('/api/Article/gethome',{})
			aticleSorts.value = res.data.sorts
			aticleSorts.value[navIdx.value].type
			getArticle()
		}
		const changePage = (e: any) => {
			page.value = e
			getArticle()
		}
		const getArticle = async () => {
			let data:any = {
				type: aticleSorts.value[navIdx.value].type,
				page: page.value
			}
			let res = await getApi('/api/Article/gethome',data)
			aticleData.value = res.data
			total.value = res.data.lists.total
		}
		const router = useRouter()
		const route = useRoute();
		const headerCompRef:any = ref(null)
		const IndustrialDetails = (item:any) => {

			if (item.sorts && item.sorts.status == 1) {
				if (!userInfo.value.phone) {
					if (headerCompRef.value) {
						headerCompRef.value.onshowLogin(1,`/articleDetails?id=${item.id}&type=${navIdx.value}`)
					}
				} else {
					const routeUrl = router.resolve(`/articleDetails?id=${item.id}&type=${navIdx.value}`).href
    				window.open(routeUrl, '_blank');
				}
			} else {
				const routeUrl = router.resolve(`/articleDetails?id=${item.id}&type=${navIdx.value}`).href
    			window.open(routeUrl, '_blank');
			}
			
		}
		const onshowLogin = () => {
			showLogin.value = !showLogin.value
			formData.value = {
				phone: '',
				password: '',
				password2: '',
				code: '',
				company: '',
				position: '',
				name: ''
			}
			clearInterval(interval.value)
			time.value = '发送验证码'
			currentTime.value = 60
			disabledT.value = false
		
		}
		const sendsmsCode = async (type:any) => {
			let data = {
				phone: formData.value.phone,
				codeType: type
			}
			const phoneRegex = /^1[34578]\d{9}$/;
			if (!phoneRegex.test(formData.value.phone)){
				ElMessage.error('请输入正确的手机号')
				return
			}
			let res:any = await postApi('/api/User/getCode',data)
			if (res.code == 0) {
				ElMessage.success(res.msg)
				getCode()
			}
		}
		const getCode = () => {
			let that = this;
			disabledT.value = true
			interval.value = setInterval(function () {
				currentTime.value--;
				time.value = `${currentTime.value}s`
				if (currentTime.value <= 0) {
					clearInterval(interval.value)
					time.value = '重新获取'
					currentTime.value = 60
					disabledT.value = false
				}
			}, 1000)
		}
		const onContact = async () => {
			if (!formData.value.company){
				ElMessage.error('请输入公司名称')
				return
			}
			if (!formData.value.name){
				ElMessage.error('请输入联系人')
				return
			}
			if (!formData.value.position){
				ElMessage.error('请输入您的职务')
				return
			}
			if (!formData.value.phone){
				ElMessage.error('请输入手机号')
				return
			}
			const phoneRegex = /^1[34578]\d{9}$/;
			if (!phoneRegex.test(formData.value.phone)){
				ElMessage.error('请输入正确的手机号')
				return
			}
			if (!formData.value.code){
				ElMessage.error('请输入验证码')
				return
			}
			let data = {
				phone: formData.value.phone,
				code: formData.value.code,
				company: formData.value.company,
				contacts: formData.value.name,
				duties: formData.value.position
			}
			let res:any = await postApi('/api/User/register',data)
			if (res.code == 0) {
				ElMessage.success(res.msg)
				showLogin.value = false
				clearInterval(interval.value)
				time.value = '发送验证码'
				currentTime.value = 60
				disabledT.value = false 
			} 
		}
		onMounted(() => {
			getArticleSorts()
			let userInfoT = localStorage.getItem('userInfo') || ''
			userInfo.value  = userInfoT?JSON.parse(userInfoT):{}

		})
		watch(route, (newValue, oldValue) => {
		
			if (newValue){
				let userInfoT = localStorage.getItem('userInfo') || ''
				userInfo.value  = userInfoT?JSON.parse(userInfoT):{}

			}
			
		})
		return {
			navIdx,
			onNav,
			aticleData,
			total,
			IndustrialDetails,
			userInfo,
			indexNav,
			navTopId,
			formData,
			showLogin,
			onshowLogin,
			sendsmsCode,
			disabledT,
			time,
			onContact,
			aticleSorts,
			changePage,
			headerCompRef,
			page
		}
	}
});
</script>
<style lang="scss" scoped>
.homeMain{
	min-width: 1380px;
	margin: auto;
}
.bannerBox{
	position: relative;
	.img{
		width: 100%;
	}
	.content{
		position: absolute;
		left: 12rem;
		top: 13rem;
		.info{
			.title{
				display: flex;
				align-items: center;
				margin-bottom: -1rem;
				.t{
					font-size: 2.4rem;
					font-weight: 700;
					color: #1F1F1F;
				}
				.tt{
					font-size: 2.4rem;
					font-weight: 700;
					background: radial-gradient(159% 159% at 29% -68%, rgba(253, 224, 209, 0.8) 26%, rgba(254, 207, 150, 0.304) 58%, rgba(243, 86, 55, 0) 99%), #F34F5A;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
					text-fill-color: transparent;
				}
				.icon{
					width: 5.2rem;
					margin-left: -1rem;
				}
			}
			.h1{
				font-size: 2.4rem;
				font-weight: 700;
				color: #1F1F1F;
				margin: 0;
			}
			.h4{
				font-size: 1.3rem;
				color: #434343;
				font-weight: normal;
				margin: .8rem 0 0 0;
			}
		}
	}
}
.serviceBox{
	.title{
		text-align: center;
		background-image: url('@/assets/i2.png');
		height: 10.6rem;
		background-repeat: no-repeat;
		background-size: cover;
		.text{
			font-size: 2.1rem;
			font-weight: 700;
			color: #1F1F1F;
			padding-top: 3rem;
		}
	}
	.dataBlock{
		padding: 0 9rem;
		.dataLine{
			position: relative;
			margin-top: -4rem;
			padding: 0 4.1rem;
			.img1{
				width: 95%;
				position: absolute;
				left: 50%;
				bottom: 6rem;
				z-index: 10;
				transform: translate(-50%, 0);
				
			}
			.img2{
				width: 100%;
			}
		}
		.content{
			padding: 3rem 4.1rem 6rem 4.1rem;
			background-image: url('@/assets/i15.png');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
			z-index: 9;
			margin: -1.5rem 0 0 0;
			background-color: rgba(255, 255, 255, 0.9);
			backdrop-filter: blur(20px);
			box-shadow: 0px 8px 20px 5px rgba(121, 113, 110, 0.1);
			.titleBox{
				display: flex;
				align-items: center;
				position: relative;
				z-index: 999;
				padding-bottom: 3.75rem;
				border-bottom: solid 1px rgba(176, 176, 176, 0.2);
				
				.num{
					font-size: 4.1rem;
					color: #F73100;
					font-family: '900Heavy'
				}
				.t{
					font-size: 1.8rem;
					color: #1F1F1F;
					font-weight: 700;
					margin-left: 1.2rem;
					margin-top: 1rem;
				}
				.btnT{
					
					background-color: #F73100;
					padding: 0 2.5rem;
					line-height: 3rem;
					border-radius: .1rem;
					margin-left: auto;
					position: relative;
					cursor: pointer;
					z-index: 9999;
					&:hover{
						.showCode{
							display: block;
						}
					}
					.tt{
						font-size: .9rem;
						color: #fff;
					}
					.showCode{
						position: absolute;
						top: 3rem;
						left: 0;
						width: 100%;
						display: none;
						.text{
							position: absolute;
							width: 100%;
							text-align: center;
							left: 0;
							top: 1.2rem;
							.s{
								font-size: .9rem;
								color: #fff;
							}
						}
						.img{
							width: 100%;
							padding-top: .3rem;
						}
					}
				}
			}
			.infoBox{
				display: flex;
				margin-top: 3.75rem;
				.rigth{
					flex: 1;
					padding-left: 5.5rem;
					margin-left: 12rem;
					display: flex;
					justify-content: space-between;
					border-left: solid 1px rgba(176, 176, 176, 0.2);
					padding-right: 2.5rem;
					.item{
						.tt{
							display: flex;
							align-items: center;
							margin-bottom: 1.4rem;
							.i{
								width: 1.2rem;
							}
							.t{
								font-size: 1.1rem;
								font-weight: 700;
								color: #1F1F1F;
								flex: 1;
								margin-left: .6rem;
							}
						}
						.ss{
							display: flex;
							align-items: center;
							justify-content: flex-end;
							margin-bottom: .5rem;
							&:last-child{
								margin-bottom: 0;
							}
							.i{
								width: .9rem;
							}
							.t{
								font-size: .8rem;
								color: #1F1F1F;
								margin-left: .6rem;
							}
						}
					}
				}
				.left{
					.line{
						width: 2.2rem;
						height: .4rem;
						opacity: 0.3;
						background: radial-gradient(320% 320% at 26% -169%, rgba(253, 224, 209, 0.8) 26%, rgba(254, 207, 150, 0.304) 58%, rgba(243, 86, 55, 0) 99%), #F34F5A;
					}
					.text{
						font-size: 1rem;
						line-height: 2.2rem;
						color: #1F1F1F;
						margin-top: 1.5rem;
					}
				}
			}
		}
	}
	.dataBlock2{
		padding: 0 5rem;
		margin-top: 4.8rem;
		.content{
			padding: 3rem 4.1rem;
			background-image: url('@/assets/i15.png');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: relative;
			z-index: 9;
			background-color: rgba(255, 255, 255, 0.9);
			backdrop-filter: blur(20px);
			box-shadow: 0px 8px 20px 5px rgba(121, 113, 110, 0.1);
			margin: 0 4rem;
			.titleBox{
				display: flex;
				align-items: center;
				padding-bottom: 3.75rem;
				border-bottom: solid 1px rgba(176, 176, 176, 0.2);
				.num{
					font-size: 4.1rem;
					color: #F73100;
					font-family: '900Heavy'
				}
				.t{
					font-size: 1.8rem;
					color: #1F1F1F;
					font-weight: 700;
					margin-left: 1.2rem;
					margin-top: 1rem;
				}
				.btnT{
					font-size: .9rem;
					color: #fff;
					background-color: #F73100;
					padding: 0 2.5rem;
					border-radius: .1rem;
					line-height: 3rem;
					margin-left: auto;
					position: relative;
					cursor: pointer;
					z-index: 999;
					&:hover{
						.showCode{
							display: block;
						}
					}
					.tt{
						font-size: .9rem;
						color: #fff;
					}
					.showCode{
						position: absolute;
						top: 3rem;
						left: 0;
						width: 100%;
						display: none;
						.text{
							position: absolute;
							width: 100%;
							text-align: center;
							left: 0;
							top: 1.2rem;
							.s{
								font-size: .9rem;
								color: #fff;
							}
						}
						.img{
							width: 100%;
							padding-top: .3rem;
						}
					}
				}
			}
			.itemBoxNum{
				display: flex;
				margin-top: 3.75rem;
				justify-content: center;
				padding: 0 3.2rem;
				.item{
					padding: .4rem;
					background-image: url('@/assets/i14.png');
					background-repeat: no-repeat;
					background-size: 1.6rem 1.6rem;
					background-position: .1rem .4rem;
					margin: auto;
					color: #1F1F1F;
					.num{
						font-size: 1.7rem;
						font-family: '800ExtraBold'
					}
					.lable{
						font-size: .8rem;
						margin-top: .3rem;
					}
				}
			}
		}
		.imgBox{
			display: flex;
			margin-top: -2.5rem;
			position: relative;
			.item{
				flex: 1;
				margin-right: 1.2rem;
				position: relative;
				&:last-child{
					margin-right: 0;
				}
				.img{
					width: 100%;
				}
				.titleB{
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					background: radial-gradient(138% 138% at 50% 0%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(82% 82% at 50% 0%, rgba(229, 206, 159, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
					backdrop-filter: blur(1.5rem);
					padding: 1.3rem 0;
					.i1{
						width: 1.3rem;
					}
					.i2{
						width: 1.3rem;
						transform: rotate(180deg);
					}
					.t{
						font-size: 1rem;
						color: #1F1F1F;
						margin: 0 .7rem;
					}
				}
			}
		}
	}
}
.tradingBox{
	background-image: url('@/assets/i16.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-top: 4.5rem;
	.dataBlock{
		padding: 0 11.2rem;
		.dataLine{
			position: relative;
			margin-top: -4rem;
			.img1{
				width: 100%;
				position: absolute;
				left: 0;
				bottom: 6rem;
				z-index: 10;
			}
			.img2{
				width: 100%;
			}
		}
		.content{
			padding: 4rem 0 8.8rem 0;
			position: relative;
			z-index: 9;
			.titleBox{
				display: flex;
				align-items: center;
				
				.num{
					font-size: 4.1rem;
					color: #1F1F1F;
					font-family: '900Heavy'
				
				}
				.t{
					font-size: 1.8rem;
					color: #1F1F1F;
					font-weight: 700;
					margin-left: 1.2rem;
					margin-top: 1rem;
				}
				.btnT{
					font-size: .9rem;
					color: #fff;
					background-color: #F73100;
					padding: 0 2.5rem;
					border-radius: .1rem;
					line-height: 3rem;
					margin-left: auto;
					position: relative;
					cursor: pointer;
					z-index: 999;
					&:hover{
						.showCode{
							display: block;
						}
					}
					.tt{
						font-size: .9rem;
						color: #fff;
					}
					.showCode{
						position: absolute;
						top: 3rem;
						left: 0;
						width: 100%;
						display: none;
						.text{
							position: absolute;
							width: 100%;
							text-align: center;
							left: 0;
							top: 1.2rem;
							.s{
								font-size: .9rem;
								color: #fff;
							}
						}
						.img{
							width: 100%;
							padding-top: .3rem;
						}
					}
					
				}
			}
			.itemBox{
				display: flex;
				margin-top: 3rem;
				justify-content: space-between;
				.item{
					flex: 0 0 calc(33.333% - .8rem);
					position: relative;
					background-color: #fff;
					box-shadow: 0px .8rem 3rem .5rem rgba(116, 116, 116, 0.1);
					
					background-repeat: no-repeat;
					background-size: cover;
					background-position: bottom;
					height: 31rem;
					margin-right: .8rem;
					&:last-child{
						margin-right: 0;
					}
					.main{
						padding: 2.9rem 2.2rem;
						.name{
							font-size: 1.4rem;
							color: #1F1F1F;
							font-weight: 700;
						}
						.msg{
							font-size: .8rem;
							color: rgba(31, 31, 31, 0.7);
							line-height: 1.2rem;
							margin-top: 1.1rem;
						}
						.tagBox{
							margin-top: 2.4rem;
							.tag{
								display: flex;
								align-items: center;
								margin-bottom: 1.2rem;
								.i{
									width: 1.8rem;
								}
								.t{
									font-size: .9rem;
									margin-left: .7rem;
									color: #383838;
								}
							}
						}
					}
				}
				.bg1{
					background-image: url('@/assets/i22.png');
				}
				.bg2{
					background-image: url('@/assets/i23.png');
				}
				.bg3{
					background-image: url('@/assets/i28.png');
				}
			}
		}
	}
}
.newsBox{
	padding: 0 7rem;
	.title{
		text-align: center;
		background-image: url('@/assets/i2.png');
		height: 10.6rem;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center;
		position: relative;
		.text{
			font-size: 2.1rem;
			font-weight: 700;
			color: #1F1F1F;
			position: absolute;
			left: 51%;
			top: 2.4rem;
			transform: translate(-50%, 0);
		}
	}
	.content{
		padding: 0 6rem;
		margin-top: -3rem;
		.navBox{
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				font-size: 1rem;
				color: rgba(0, 0, 0, 0.7);
				margin: 0 3rem;
				cursor: pointer;
				position: relative;
			}
			.active{
				font-size: 1.2rem;
				color: rgba(0, 0, 0, 1);
				font-weight: 700;
				&::after{
					content: '';
					position: absolute;
					width: 95%;
					bottom: 0;
					background: radial-gradient(600% 600% at 25% -253%, rgba(255, 214, 200, 0.8) 0%, rgba(254, 217, 186, 0.304) 58%, rgba(255, 184, 170, 0) 97%), #FF7070;
					height: .5rem;
					left: 50%;
					z-index: -1;
					border-radius: .2rem;
					opacity: 0.6;
					transform: translate(-50%,0);
				}
			}
		}
		.newBox{
			display: flex;
			margin-top: 3rem;
			.right{
				flex: 1;
				.list{
					display: flex;
					margin-bottom: 1.8rem;
					cursor: pointer;
					&:last-child{
						margin-bottom: 0;
					}
					.info{
						flex: 1;
						.t{
							font-size: 1rem;
							font-weight: 500;
							color: #1F1F1F;
							white-space: break-spaces;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
							overflow: hidden;
							font-family: 'SourceHanSansCNMedium';
						}
						.m{
							font-size: .8rem;
							color: rgba(31, 31, 31, 0.7);
							margin-top: .6rem;
							white-space: break-spaces;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
							overflow: hidden;
							height: 1.2rem;
						}
					}
					.moreTime{
						margin-left: .75rem;
						.more{
							display: flex;
							align-items: center;
							justify-content: flex-end;
							.t{
								font-size: .75rem;
								color: #1F1F1F;
							}
							.i{
								width: 1rem;
							}
						}
						.time{
							font-size: .75rem;
							color: rgba(31, 31, 31, 0.7);
							margin-top: .5rem;
						}
					}
				}
			}
			.left{
				flex: 0 0 27rem;
				cursor: pointer;
				.time{
					font-size: .7rem;
					color: rgba(31, 31, 31, 0.7);
					margin-top: .9rem;
				}
				.msg{
					font-size: .8rem;
					color: rgba(31, 31, 31, 0.7);
					margin-top: .7rem;
					white-space: break-spaces;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
				.titleT{
					font-size: 1rem;
					color: #1F1F1F;
					margin-top: 2.1rem;
					font-weight: 500;
					cursor: pointer;
					white-space: break-spaces;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					font-family: 'SourceHanSansCNMedium';
				}
				.imgBox{
					width: 25.4rem;
					height: 16.4rem;
					position: relative;
					&::after{
						content: '';
						width: 100%;
						height: 100%;
						background: linear-gradient(212deg, #F2F0F0 11%, rgba(255, 255, 255, 0) 167%, rgba(255, 255, 255, 0.0075) 170%);
						position: absolute;
						right: -1rem;
						bottom: -1rem;
						z-index: -1;
					}
					.img{
						width: 100%;
						height: 100%;
						
						
					}
				}
			}
			.line{
				background-color: rgba(216, 216, 216, 0.5);
				width: 1px;
				margin: 0 4rem;
				height: 23.75rem;
			}
		}
	}
	.pagination{
		display: flex;
		justify-content: center;
		padding: 2.9rem 0;
		
		:deep(.el-pagination){
			li{
				border: 1px solid #DEDEDE;
				margin: 0 .25rem;
				border-radius: .2rem;
				&:hover{
					color: #F73100;
					border: 1px solid #F73100;
				}
			}
			.btn-prev{
				border: 1px solid #DEDEDE;
				margin: 0 .25rem;
				border-radius: .2rem;
				&:hover{
					color: #F73100;
					border: 1px solid #F73100;
				}
			}
			.btn-next{
				border: 1px solid #DEDEDE;
				margin: 0 .25rem;
				border-radius: .2rem;
				&:hover{
					color: #F73100;
					border: 1px solid #F73100;
				}
			}
			.is-active{
				color: #F73100;
				border: 1px solid #F73100;
			}
		}
	}
}
.loginContent{
	.bg{
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
		z-index: 10000;
	}
	.contentT{
		position: fixed;
		width: 49.4rem;
		background-color: #fff;
		background-image: url('@/assets/BG_.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 10001;
		border-radius: .6rem;
		.box{
			padding: 1.8rem;
			.close{
				width: 1rem;
				height: 1rem;
				margin-left: auto;
				cursor: pointer;
			}
			.fromBox{
				padding: 1.5rem 3rem;
				width: 17.5rem;
				.error{
					font-size: .6rem;
					color: #F73100;
					margin-top: .5rem;
					text-align: center;
					height: 1rem;
					line-height: 1rem;
				}
				.msgT{
					font-size: .65rem;
					color: #8C8C8C;
					cursor: pointer;
					margin-top: .5rem;
					text-align: center;
					.span{
						text-decoration: underline;
					}
				}
				.btnT{
					color: #F7FBFE;
					font-size: .75rem;
					font-weight: 500;
					line-height: 2.2rem;
					border-radius: .2rem;
					margin-top: .9rem;
					text-align: center;
					cursor: pointer;
					background: linear-gradient(0deg, rgba(247, 49, 0, 0.5), rgba(247, 49, 0, 0.5)), radial-gradient(159% 159% at 29% -68%, rgba(253, 224, 209, 0.5) 26%, rgba(254, 207, 150, 0.19) 58%, rgba(243, 86, 55, 0) 99%), #F34F5A;
				}
				.btnTT{
					color: #F7FBFE;
					font-size: .75rem;
					font-weight: 500;
					line-height: 2.2rem;
					border-radius: .2rem;
					margin-top: 2rem;
					text-align: center;
					cursor: pointer;
					background: linear-gradient(0deg, rgba(247, 49, 0, 0.5), rgba(247, 49, 0, 0.5)), radial-gradient(159% 159% at 29% -68%, rgba(253, 224, 209, 0.5) 26%, rgba(254, 207, 150, 0.19) 58%, rgba(243, 86, 55, 0) 99%), #F34F5A;
				}
				.forgotBox{
					display: flex;
					align-items: center;
					margin-top: 1rem;
					.jizhu{
						display: flex;
						align-items: center;
						cursor: pointer;
						line-height: 1;
						.i{
							width: .7rem;
							height: .7rem;
						}
						.t{
							font-size: .65rem;
							margin-left: .3rem;
							color: #8C8C8C;
						}
					}
					.wang{
						margin-left: auto;
						font-size: .65rem;
						color: #8C8C8C;
						cursor: pointer;
					}
				}
				
				.inputBox{
					margin-top: 1.2rem;
					.input{
						width: 100%;
						margin-bottom: .6rem;
						position: relative;
						display: flex;
						align-items: center;
						.yanma{
							position: absolute;
							font-size: .65rem;
							color: #8C8C8C;
							right: 1rem;
							top: 50%;
							transform: translate(0,-50%);
							padding-left: 1rem;
							line-height: 1;
							cursor: pointer;
							width: 3.5rem;
							text-align: center;
							&::after{
								content: '';
								position: absolute;
								width: 1px;
								height: 80%;
								background-color: rgba(140, 140, 140, 0.5);
								left: 0;
								top: 50%;
								transform: translate(0,-50%);
							}
						}
						&:last-child{
							margin-bottom: 0;
						}
						.lable{
							font-size: .65rem;
							width: 3rem;
							text-align: justify;
							margin-right: .9rem;
						}
						:deep(.el-input){
							flex: 1;
							line-height: 2.4rem;
							height: 2.4rem;
							font-size: .7rem;
							--el-input-hover-border: #F73100;
							--el-input-focus-border: #F73100;
							--el-input-hover-border-color: #F73100;
							--el-input-clear-hover-color: #F73100;
							--el-input-focus-border-color: #F73100;
							--el-input-text-color: #3D3D3D;
							--el-input-width: 100%;
							.el-input__wrapper{
								padding: 0 1rem;
							}
						}
						.codeIpnut{
							:deep(.el-input__wrapper){
								padding-right: 35%;
							}
							
						}
					}
				}
				.title{
					font-size: 1.3rem;
					font-weight: 700;
					color: #3D3D3D;
				}	
				.navBox{
					display: flex;
					align-items: center;
					margin-top: 1.5rem;
					.t{
						position: relative;
						font-size: .8rem;
						color: #434343;
						margin-right: 3.6rem;
						cursor: pointer;
						&:last-child{
							margin-right: 0;
						}
					}
					.active{
						font-size: .8rem;
						color: rgba(0, 0, 0, 1);
						font-weight: 700;
						&::after{
							content: '';
							position: absolute;
							width: 90%;
							bottom: 0;
							background: rgba(247, 49, 0, 0.3);
							height: .5rem;
							left: 50%;
							z-index: -1;
							transform: translate(-50%, 0)
						}
					}

				}
			}
			.fromBox2{
				width: 18.5rem;
			}
		}
	}
}
</style>
