<template>
	<div class="footer" >
		<div class="conentT">
			<div class="content">
				<div class="itemBox itemBox2">
					<div class="title">关于我们</div>
					<div class="msg">{{ footerData.about_content }}</div>
				</div>
				<div class="itemBox">
					<div class="title">联系我们</div>
					<div class="it">
						<div class="l">电话</div>
						<div class="t">{{footerData.about_phone}}</div>
					</div>
					<div class="it">
						<div class="l">邮箱</div>
						<div class="t">{{footerData.about_email}}</div>
					</div>
					<div class="it">
						<div class="l">地址</div>
						<div class="t">{{footerData.about_address}}</div>
					</div>
				</div>
				<div class="itemBox">
					<div class="title">关注我们</div>
					<div class="imgBox">
						<img class="img" :src="footerData.about_wxcode" alt="" srcset="">
						<img class="img" :src="footerData.about_wxxcx" alt="" srcset="">
					</div>
				</div>
			</div>
			<div class="company">
				<div v-html="footerData.about_beian"></div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent,ref,onMounted,onUnmounted,watch } from 'vue';
import {postApi,getApi} from '@/utils/index'
import { useRoute,useRouter } from "vue-router"
export default defineComponent({
	name: 'footerComp',
	props: {
		msg: String,
	},
	setup() {
		const footerData:any = ref([])	
		const seoData:any = ref([])	
		const route = useRoute();
		const router = useRouter()
		const getfooter = async () => {
			let res:any = await getApi('/api/index/getfooter',{})
			footerData.value = res.data
			console.log(route);
			
			let data:any = {
				method: ''
			}
			if (route.name == 'panel') {
				data.method = '钕铁硼'
			} else if (route.name == 'panel2') {
				data.method = '钐钴'
			}
			if (route.name == 'productData' || route.name == 'productData2' || route.name == 'productHistory') {
				data.method = route.query.title
			}
			let res2:any = await getApi('/api/index/getseo',data)
			seoData.value = res2.data
			if (data.method) {
				document.title = seoData.value.title
				var keywords:any = document.querySelector('meta[name="keywords"]');
				keywords.setAttribute('content', seoData.value.keywords);
				var description:any = document.querySelector('meta[name="description"]');
				description.setAttribute('content', seoData.value.description);
			}
			if (route.name == 'home'){
				document.title = '找磁材-中国领先的磁性材料产业互联网平台'
				var keywords:any = document.querySelector('meta[name="keywords"]');
				keywords.setAttribute('content', '找磁材-中国领先的磁性材料产业互联网平台');
				var description:any = document.querySelector('meta[name="description"]');
				description.setAttribute('content', '找磁材-中国领先的磁性材料产业互联网平台');
			}
			
		}
		onMounted(() => {
			getfooter()
		})
		watch(route, (newValue, oldValue) => {
			if (newValue){
				getfooter()
			}
			
		})
		return {
			footerData
		}
	}
});
</script>

<style scoped lang="scss">
.footer{
	background: linear-gradient(321deg, rgba(255, 255, 255, 0) 27%, rgba(255, 255, 255, 0.3) 36%, rgba(255, 255, 255, 0) 43%), linear-gradient(169deg, #FFFFFF -8%, rgba(255, 255, 255, 0) 38%), linear-gradient(180deg, rgba(226, 230, 235, 0.8) 0%, rgba(255, 255, 255, 0) 69%);
	backdrop-filter: blur(10px);
	background-size: 100% 1502px;
	min-width: 1380px;
	margin: auto;
	.conentT{
		padding: 0 10rem;
	}
	.content{
		display: flex;
		justify-content: space-between;
		padding: 4.6rem 0 0 0;
		.itemBox{
			margin-right: 4rem;
			&:last-child{
				margin-right: 0;
			}
			.title{
				position: relative;
				font-size: .9rem;
				color: #1F1F1F;
				font-weight: 700;
				padding-bottom: .5rem;
				margin-bottom: 1.1rem;
				&::after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 1.2rem;
					height: 2px;
					background: #F73100;
				}
			}
			.msg{
				font-size: .7rem;
				color: #434343;
				line-height: 1.5rem;
			}
			.it{
				display: flex;
				align-items: baseline;
				font-size: .7rem;
				color: #434343;
				margin-bottom: .6rem;
				.l{
					margin-right: 1.5rem;
				}
				.t{
					flex: 1;
				}
			}
			.imgBox{
				display: flex;
				.img{
					width: 6rem;
					margin-right: 3rem;
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
		.itemBox2{
			flex: 0 0 40%;
			text-align: justify;
		}
	}
	.company{
		font-size: .65rem;
		color: #7E7E7E;
		text-align: center;
		padding: 3.7rem 0 2.5rem 0;
	}
}
</style>
<style>
.company a{
	color: #7E7E7E;
	text-decoration: none;
	margin-left: 1rem;
}
</style>
