import request from './request';
export const getApi = (url:string,query:object) => {
    return request({
        url: `${url}`,
        method: 'get',
        params: query
    });
};

export const postApi = (url:string,data:object) => {
    return request({
        url: `${url}`,
        method: 'post',
        data: data
    });
};

export const getFile = (url:string,data:object) => {
    return request({
        url: `${url}`,
        responseType: 'blob',
        data: data
    });
};