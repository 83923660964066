import { createStore } from 'vuex'

export default createStore({
  state: {
    getTopNav: '',
    navTagIdxT: ''
  },
  getters: {
  },
  mutations: {
    get_TopNav(state:any,data:any) {
      state.getTopNav = data
    },
    nav_TagIdx(state:any, data: any) {
      state.navTagIdx = data
    }
  },
  actions: {
  
  },
  modules: {
  }
})
